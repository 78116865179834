import { rem } from 'polished'
import { useTransform } from 'framer-motion'
import { BoxProps, chakra, useBreakpointValue } from '@chakra-ui/react'

import { MotionBox } from 'components/Motion'

import useViewportAnimation from 'hooks/useViewportAnimation'
import useMounted from 'hooks/useMounted'

import { BreakpointKeysInterface } from 'constants/breakpoints'

interface GlowStickProps {
  glow: {
    color: string
    strength?: number
  }
  rotation: {
    [key in keyof BreakpointKeysInterface]?: number
  }
  className?: string
  parallax?: [number, number]
}

export type GlowStickType = GlowStickProps & BoxProps

const DEFAULT_STRENGTH = 4
const DEFAULT_PARALLAX = [-40, 40]

const GlowStick = ({ className, glow, rotation, parallax }: GlowStickProps) => {
  const bp = useBreakpointValue({ base: 'base', xl: 'xl' })
  const rotationByBreakpoint = rotation?.[bp] || Object.values(rotation)[0]

  const strength = glow?.strength || DEFAULT_STRENGTH
  const parallaxValues = parallax || DEFAULT_PARALLAX

  const [progress, ref] = useViewportAnimation(0, 1)

  const progressParallax = useTransform(progress, [0, 1], [parallaxValues[0], parallaxValues[1]])
  const progressRotation = useTransform(progress, [0, 1], [rotationByBreakpoint, rotationByBreakpoint - 8])

  const isMounted = useMounted()

  return (
    <MotionBox
      className={className}
      pos="relative"
      h="1px"
      bgColor={glow.color}
      pointerEvents="none"
      _before={{
        content: '""',
        pos: 'absolute',
        top: rem(strength / -2),
        left: rem(-strength),
        display: 'block',
        h: rem(strength),
        w: `calc(100% + ${rem(strength * 2)})`,
        borderRadius: rem(strength / 2),
        bgColor: glow.color,
        filter: `blur(${rem(strength * 2)})`,
      }}
      style={
        isMounted && {
          rotate: progressRotation,
          y: progressParallax,
        }
      }
      ref={ref}
    />
  )
}

export default chakra(GlowStick)
