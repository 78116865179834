import { useState, useEffect } from 'react'
import { useViewportScroll, useTransform, useSpring } from 'framer-motion'

import useElementRect from 'hooks/useElementRect'

interface optionsProps {
  trackingStart?: number
  disabledEase?: boolean
}

/**
 * Animates an element based on its position in the viewport.
 * Defaults to start tracking when:
 * - the top of the element hits the bottom of the screen
 * - the bottom of the element hits the top of the screen
 * @param {number} startValue
 * @param {number} endValue
 * @param {object} options
 */
const useViewportAnimation = (startValue: number, endValue: number, options?: optionsProps) => {
  const [rangeStart, setRangeStart] = useState<number | null>(null)
  const [rangeEnd, setRangeEnd] = useState<number | null>(null)

  const [rect, ref] = useElementRect()
  const { scrollYProgress } = useViewportScroll()

  const progressValue = useTransform(scrollYProgress, [rangeStart, rangeEnd], [startValue, endValue])
  const progressSpringValue = useSpring(progressValue, { stiffness: 400, damping: 90 })
  const progress = options?.disabledEase ? progressValue : progressSpringValue

  useEffect(() => {
    if (!rect) {
      return
    }

    const windowHeight = window.innerHeight
    const offsetStart = !isNaN(options?.trackingStart) ? options.trackingStart : windowHeight
    const offsetEnd = !isNaN(options?.trackingStart) ? 0 : windowHeight

    const progressStart = rect.y - offsetStart
    const progressEnd = progressStart + rect.height + offsetEnd
    const scrollHeight = document.body.clientHeight - windowHeight

    setRangeStart(progressStart / scrollHeight)
    setRangeEnd(progressEnd / scrollHeight)
  }, [rect])

  return [progress, ref, rect] as const
}

export default useViewportAnimation
